<template>
    
    <div class="expandedContent" :class="{reverse: reverse}" @click="LoadPage" @mousedown.middle="OpenTab">
        <font-awesome-icon :icon="icon" class="icon" size="2x" />
        <p>{{title}}</p>
    </div>
    
</template>

<script>

    export default {
        props: [`title`, `icon`, `link`, `reverse`],
        methods: {
            OpenTab() {
                window.open(this.link, `_blank`)
            },
            LoadPage() {
                this.$router.push(this.link)
                this.$emit(`OpenPage`)
            },
        }
    }
  
</script>

<style scoped>

    .icon {
        width: 30px;
        margin: 0px 10px;
    }

    .expandedContent {
        height: 50px;
        width: 100%;
        display: flex;
        margin: 0px;
        padding: 10px 0px;
    }

    .expandedContent:hover {
        color: gray;
        cursor: pointer;
    }

    .expandedContent p {
        margin: auto;
        margin-left: 10px;
        font-size: 20px;
    }

    .reverse {
        background-color: #181820 !important;
    }

</style>