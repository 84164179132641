<template>
    
    <footer>

        <div id="socialsContainer">
            <font-awesome-icon @click="OpenSite(`https://tiktok.com/@fandle.io`)" icon="fa-brands fa-tiktok" class="socialButton" size="2x" />
            <font-awesome-icon @click="OpenSite(`https://tiktok.com/@fandle.pop`)" icon="fa-brands fa-tiktok" class="socialButton pop" size="2x" />
            <font-awesome-icon @click="OpenSite(`https://twitter.com/fandleio`)" icon="fa-brands fa-twitter" class="socialButton" size="2x" />
            <font-awesome-icon @click="OpenSite(`https://discord.gg/bCHKR4XKSE`)" icon="fa-brands fa-discord" class="socialButton" size="2x" />
        </div>

        <a href="/privacy" id="privacy">Privacy Terms</a>

        <p id="tc">© 2024 Fandle.io</p>

    </footer>

</template>

<script>

    export default {
        methods: {
            OpenSite(url) {
                window.open(url)
            },
        }
    }

</script>

<style>

    #footerSub {
        margin: 10px;
    }

    footer {
        width: 100%;
        height: auto;
        margin-top: auto;
        padding: 20px 0px;
        background-color: #15151d;
    }

    #socialsContainer {
        width: 80%;
        min-width: 350px;
        margin: auto;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
    }

    .socialButton {
        width: 30px;
        height: 30px;
        margin: 10px;
        padding: 7px;
        background-color: #121212;
        cursor: pointer;
    }

    #tc {
        margin-top: 10px;
    }

</style>
