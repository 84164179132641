<template>
    
    <div id="pageCover" v-if=expanded @click="$emit(`ToggleExpand`)"></div>

    <div id="expanded" v-if="expanded && this.$store.state.Account">
       
        <div v-if="!notificationsExpanded">

            <div id="returnContainer">
                <font-awesome-icon v-if="!expandedProfile" icon="fa-solid fa-arrow-right" id="expand" size="lg" @click="$emit(`ToggleExpand`)" />
            </div>

            <h3>Notifications</h3>
            <div id="notificationsContainer">
                <AppNotification v-if="this.$store.state.Account.notifications[0]" :notification="this.$store.state.Account.notifications[0]" />
                <p id="readAll" @click="ExpandNotifications">Read all notifications ({{ this.$store.state.Account.unreadNotifications }} unread)</p>
            </div>
        
            <h3>Account</h3>
            <AppExpandContent title="Profile" icon="fa-solid fa-user" :link="`/u/${this.$store.state.Account.username}`" @OpenPage="$emit(`ToggleExpand`)" />
            <AppExpandContent title="Game History" icon="fa-solid fa-save" :reverse="true"  link="/saved" @OpenPage="$emit(`ToggleExpand`)" />
            <AppExpandContent title="Edit Profile" icon="fa-solid fa-edit" link="/edit" @OpenPage="$emit(`ToggleExpand`)" />
            <AppExpandContent title="Account Settings" icon="fa-solid fa-cog" :reverse="true"  link="/account" @OpenPage="$emit(`ToggleExpand`)" />
             
            <div class="expandedContent" @click="Discord" @mousedown.middle="Discord">
                <font-awesome-icon icon="fa-solid fa-life-ring" class="icon" size="2x" />
                <p>Support</p>
            </div>
            <div id="logout" class="expandedContent reverse" @click="Logout">
                <font-awesome-icon icon="fa-solid fa-door-open" class="icon" size="2x" />
                <p>Log Out</p>
            </div>

            
            <h3>Content</h3>
            <AppExpandContent title="Create Collection" icon="fa-solid fa-plus-circle" link="/create" @OpenPage="$emit(`ToggleExpand`)" />
            <AppExpandContent title="Collection Access" icon="fa-solid fa-key" :reverse="true" link="/access" @OpenPage="$emit(`ToggleExpand`)" />
            <AppExpandContent title="Create Quiz" icon="fa-solid fa-plus-circle" link="/q/create" @OpenPage="$emit(`ToggleExpand`)" />
            <AppExpandContent title="Created Quizzes" icon="fa-solid fa-list" :reverse="true" link="/created" @OpenPage="$emit(`ToggleExpand`)" />


            <div v-if="this.$store.state.Account.isAdmin">
                <h3>Admin</h3>
                <AppExpandContent title="Create Playlist" icon="fa-solid fa-plus-circle" link="/p-create" @OpenPage="$emit(`ToggleExpand`)" />
                <AppExpandContent title="Guess Artists" icon="fa-solid fa-plus-circle" :reverse=true link="/guess-create" @OpenPage="$emit(`ToggleExpand`)" />
            </div>
        
        </div>

        <div v-else>
            <div id="returnContainer">
                <font-awesome-icon v-if="!expandedProfile" icon="fa-solid fa-arrow-right" id="expand" size="lg" @click="notificationsExpanded = false" />
            </div>

            <h3>Notifications</h3>
            <div id="notificationsContainer">
                <div v-for="notification in this.$store.state.Account.notifications" :key="notification.notificationId">
                    <AppNotification :notification="notification" />
                </div>
            </div>

        </div>

        <div id="end"></div>
        
    </div>
    
</template>

<script>

    import axios from "axios"

    import AppNotification from "./AppNotification.vue"
    import AppExpandContent from "./AppExpandContent.vue"

    export default {
        props: [`expanded`],
        components: {
            AppNotification,
            AppExpandContent
        },
        data() {
            return {
                notificationsExpanded: false
            }
        },
        methods: {
            ExpandNotifications() {
                this.notificationsExpanded = true
                axios.post(`${this.$store.state.apiUrl}/accounts/read-notifications`, {
                    token: localStorage.getItem(`token`),
                })
                .catch((err) => {
                    this.error = err.response.data.error
                })
            },
            Discord() {
                window.open("https://discord.gg/bCHKR4XKSE")
            },
            Logout() {
                localStorage.removeItem(`token`)
                this.$store.commit(`CLEAR_ACCOUNT`)
                this.$router.push(`/`)
            }
        },
        updated() {
            if (this.expanded && !this.$store.state.Account) {
                this.$router.push(`/login`)
            }
        }
    }
  
</script>

<style scoped>

    #notificationsContainer {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .notificationContainer {
        margin: 10px 0px;
        padding: 10px 10px;
        background-color: #1c1c25;
        cursor: pointer;
    }

    .notificationRead {
        background-color: #21212b;
    }

    #readAll {
        text-align: center;
        cursor: pointer;
    }

    #readAll:hover {
        color: #8860ee;
    }

    #end {
        margin-bottom: 200px;
    }

    .icon {
        width: 30px;
        margin: 0px 10px;
    }

    h3 {
        width: 90%;
        margin: 10px;
        margin-bottom: 5px;
        margin-top: 20px;
        text-align: left;
    }

    #returnContainer {
        padding: 35px 0px;
    }

    #expanded {
        height: 100%;
        padding-bottom: 400px;
        position: fixed;
        width: 25%;
        min-width: 350px;
        padding: 0px 10px;
        top: 0px;
        right: 0px;
        display: flex;
        flex-direction: column;
        background-color: #15151d;
        z-index: 99;
        overflow-y: scroll;
    }

    #expanded::-webkit-scrollbar {
        width: 0px; 
    }

    #expanded a {
        padding: 5px 25px;
        color: white;
        text-decoration: none;
        text-align: left;
    }

    .expandedContent {
        height: 50px;
        width: 100%;
        display: flex;
        margin: 0px;
        padding: 10px 0px;
    }

    .expandedContent:hover {
        color: gray;
        cursor: pointer;
    }

    .expandedContent p {
        margin: auto;
        margin-left: 10px;
        font-size: 20px;
    }

    .reverse {
        background-color: #181820;
    }

    #logout {
        color: #da3c3c;
    }

    #logout:hover {
        color: #da3c3c21;
    }

    #expand {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #733CFF;
        border-radius: 50%;
        padding: 10px;
    }

    #expand:hover {
        cursor: pointer;
        user-select: none;
    }

    @media screen and (max-width: 600px) {
        #expanded {
            width: 100%;
        }
    }

    #pageCover {
        position: fixed;
        width: 100%;
        height: 150%;
        top: -200px;
        background-color: rgba(0, 0, 0, 0.836);
        backdrop-filter: blur(2px);
    }

</style>
