import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import YouTube from 'vue3-youtube'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark'
  }
})

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCalendar, faStopwatch, faList, faUser, faEdit, faTrash, faPlay, faTrophy, faListNumeric, faArrowAltCircleUp,
faMapPin, faMessage, faPerson, faCertificate, faMagnifyingGlass, faDoorOpen, faLifeRing, faCog,
faKey, faArrowLeft, faArrowRight, faGamepad, faHome, faPlusCircle, faThumbTack, faMusic, faGlobe, faRecordVinyl, faCheckCircle, faQuestionCircle, faExclamationTriangle, faThumbsUp, faThumbsDown, faSave, faClipboard,
faUserCircle} from '@fortawesome/free-solid-svg-icons'

import { faXTwitter, faInstagram, faTiktok, faTwitter, faDiscord, faTwitch, faApple, faGoogle } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faCalendar, faStopwatch, faList, faUser, faEdit, faTrash, faPlay, faTrophy, faListNumeric, faArrowAltCircleUp,
faMapPin, faMessage, faPerson, faCertificate, faMagnifyingGlass, faDoorOpen, faLifeRing, faCog,
faKey, faArrowLeft, faArrowRight, faGamepad, faHome, faPlusCircle, faThumbTack,
faGamepad, faMusic, faTwitter, faInstagram, faXTwitter, faTiktok, faGlobe, faRecordVinyl,
faDiscord, faTwitch, faCheckCircle, faQuestionCircle, faExclamationTriangle,
faThumbsUp, faThumbsDown, faSave, faClipboard, faUserCircle, faApple, faGoogle)

createApp(App).component('YouTube', YouTube).component('font-awesome-icon', FontAwesomeIcon).use(store).use(vuetify).use(router).mount('#app')
