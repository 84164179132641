<template>
    
    <div class="notificationContainer" :class="{notificationRead: !notification._read}" @click="OpenNotification">
        <h4> <font-awesome-icon :icon="notification.icon" size="1x" /> {{ notification.title }}</h4>
        <p>{{ notification.desc }}</p>
    </div>
    
</template>

<script>

    export default {
        props: [`notification`],
        methods: {
            OpenNotification() {
                if (this.notification.link) {
                    window.open(this.notification.link, `_blank`)
                }
            }
        }
    }
  
</script>

<style scoped>

    .notificationContainer {
        margin: 10px 0px;
        padding: 10px 10px;
        background-color: #1c1c25;
        cursor: pointer;
    }

    .notificationRead {
        background-color: #21212b;
    }

</style>
