<template>
    
    <div id="pageCover" v-if=expanded @click="$emit(`ToggleExpand`)"></div>

    <div id="expanded" v-if="expanded">

        <div id="returnContainer">
            <font-awesome-icon v-if="!expandedProfile" icon="fa-solid fa-arrow-left" id="expand" size="lg" @click="$emit(`ToggleExpand`)" />
        </div>

        <h3>Fandle</h3>
        <AppExpandContent v-if="cookies" title="Home" icon="fa-solid fa-home" link="/" @OpenPage="$emit(`ToggleExpand`)" />
        <AppExpandContent title="Collections" icon="fa-solid fa-magnifying-glass" :reverse="true" link="/collections" @OpenPage="$emit(`ToggleExpand`)" />
        <AppExpandContent title="Quizzes" icon="fa-solid fa-question-circle" link="/q" @OpenPage="$emit(`ToggleExpand`)" />
        <AppExpandContent title="Song Battles" icon="fa-solid fa-music" link="/sb" :reverse="true" @OpenPage="$emit(`ToggleExpand`)" />

        <h3>Daily Games</h3>
        <AppExpandContent title="Guess The Artist" icon="fa-solid fa-user-circle" link="/guess" @OpenPage="$emit(`ToggleExpand`)" />
        <AppExpandContent title="All Wordles" icon="fa-solid fa-calendar" :reverse="true" link="/wordles" @OpenPage="$emit(`ToggleExpand`)" />
        <AppExpandContent title="All Heardles" icon="fa-solid fa-record-vinyl" link="/heardles" @OpenPage="$emit(`ToggleExpand`)" />

        <div v-if="this.$store.state.ArtistData.songs">
            <h3>{{ this.$store.state.ArtistData.name }}</h3>
            <AppExpandContent title="Collection Profile" icon="fa-solid fa-user" :link="`/c/${this.$store.state.ArtistData.artistId}`" @OpenPage="$emit(`ToggleExpand`)" />
            <AppExpandContent :key=game.id v-for="(game, index) in games" :reverse="index % 2 === 0"
            :title="game.title" :icon="game.icon" :link="`/${game.id}`" @OpenPage="$emit(`ToggleExpand`)" />
        </div>

        <div id="end" />

    </div>
    
</template>

<script>

    import AppExpandContent from './AppExpandContent.vue'

    export default {
        props: [`expanded`],
        components: {
            AppExpandContent
        },
        data() {
            return {
                cookies: true, // localStorage.getItem(`CookieConsent`),
                games: []
            }
        },
        methods: {
            OpenTab(page) {
                window.open(page, `_blank`)
            },
            LoadPage(page) {
                this.$router.push(page)
                this.$emit(`ToggleExpand`)
            },
        },
        watch: {
            "$store.state.ArtistData": {
                handler() {
                    this.games = [
                        {
                            id: `1sec`,
                            title: `One Second`,
                            icon: `fa-stopwatch`,
                            condition: true
                        },
                        {
                            id: `tierlist`,
                            title: `Tier List`,
                            icon: `fa-list-numeric`,
                            condition: true
                        },
                        {
                            id: `wordsearch`,
                            title: `Word Search`,
                            icon: `fa-magnifying-glass`,
                            condition: false
                        }
                    ]
                    if (this.$store.state.ArtistData.songs.length >= 16) {
                        this.games.unshift({
                            id: `higherlower`,
                            title: `Higher or Lower`,
                            icon: `fa-arrow-alt-circle-up`,
                        })
                    }
                    if (this.$store.state.ArtistData.songs.length >= 8) {
                        this.games.unshift({
                            id: `worldcup`,
                            title: `World Cup`,
                            icon: `fa-trophy`,
                        })
                    }
                    if (this.$store.state.ArtistData.songs.length >= 5) {
                        this.games.unshift({
                            id: `heardle`,
                            title: `Heardle`,
                            icon: `fa-record-vinyl`,
                        })
                    }
                    if (this.$store.state.ArtistData.wordle) {
                        this.games.unshift({
                            id: `wordle`,
                            title: `Wordle`,
                            icon: `fa-calendar`,
                        })
                    }
                }
            }
        }
    }
  
</script>

<style scoped>

    #end {
        margin-bottom: 200px;
    }

    h3 {
        width: 90%;
        margin: 10px;
        margin-bottom: 5px;
        margin-top: 20px;
        text-align: left;
    }

    #returnContainer {
        padding: 35px 0px;
    }

    #expanded {
        height: 100%;
        padding-bottom: 400px;
        position: fixed;
        width: 25%;
        min-width: 350px;
        padding: 0px 10px;
        top: 0px;
        display: flex;
        flex-direction: column;
        background-color: #15151d;
        z-index: 99;
        overflow-y: auto;
    }

    #expanded::-webkit-scrollbar {
        width: 0px; 
    }

    #expanded a {
        padding: 5px 25px;
        color: white;
        text-decoration: none;
        text-align: left;
    }

    #expand {
        position: absolute;
        top: 15px;
        left: 15px;
        background-color: #733CFF;
        border-radius: 50%;
        padding: 10px;
    }

    #expand:hover {
        cursor: pointer;
        user-select: none;
    }

    @media screen and (max-width: 600px) {
        #expanded {
            width: 100%;
        }
    }

    #pageCover {
        position: fixed;
        width: 100%;
        height: 150%;
        top: -200px;
        background-color: rgba(0, 0, 0, 0.836);
        backdrop-filter: blur(2px);
    }

</style>
