import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import axios from "axios"

const routes = [
    {
        path: '/',
        component: () => import('../views/IntroView.vue')
    },
    {
        path: '/guess',
        component: () => import('../games/GameGuess.vue')
    },
    {
        path: '/wordles',
        component: () => import('../views/WordlesView.vue')
    },
    {
        path: '/heardles',
        component: () => import('../views/HeardlesView.vue')
    },
    {
        path: '/wordle',
        component: () => import('../games/GameWordle.vue')
    },
    {
        path: '/heardle',
        component: () => import('../games/GameHeardle.vue')
    },
    {
        path: '/1sec',
        component: () => import('../games/GameOneSecond.vue')
    },
    {
        path: '/higherlower',
        component: () => import('../games/GameHigherLower.vue')
    },
    {
        path: '/worldcup',
        component: () => import('../games/GameWorldCup.vue')
    },
    {
        path: '/tierlist',
        component: () => import('../games/GameTierList.vue')
    },
    {
        path: '/wordsearch',
        component: () => import('../games/GameWordSearch.vue')
    },
    {
        path: '/wordsearch',
        component: () => import('../games/GameWordSearch.vue')
    },
    {
        path: '/sb',
        component: () => import('../views/SongBattlesView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../components/SongBattles/SongBattlesMain.vue')
            },
            {
                path: `play/:id`,
                component: () => import('../components/SongBattles/SongBattlesGame.vue'),
            },
            {
                path: `create`,
                component: () => import('../components/SongBattles/SongBattlesCreation.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
            {
                path: `edit/:id`,
                component: () => import('../components/SongBattles/SongBattlesEdit.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
        ]
    },
    {
        path: '/q',
        component: () => import('../views/QuizView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../components/Quizzes/QuizMain.vue')
            },
            {
                path: `play/:id`,
                component: () => import('../components/Quizzes/QuizPlay.vue')
            },
            {
                path: `create`,
                component: () => import('../components/Quizzes/QuizCreate.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
            {
                path: `update/:id`,
                component: () => import('../components/Quizzes/QuizUpdate.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
        ]
    },
    {
        path: '/login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/register',
        component: () => import('../views/RegisterView.vue')
    },
    {
        path: '/account',
        component: () => import('../views/AccountView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: '/edit',
        component: () => import('../views/ProfileEditView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: '/saved',
        component: () => import('../views/SavedView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: '/u/:username',
        component: () => import('../views/ProfileView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../components/Profile/ProfileMain.vue')
            },
            {
                path: `quizzes`,
                component: () => import('../components/Profile/ProfileQuizzes.vue')
            },
            {
                path: `access`,
                component: () => import('../components/Profile/ProfileCreated.vue')
            },
        ]
    },
    {
        path: '/wc/:id',
        component: () => import('../views/HistoryWCView.vue')
    },
    {
        path: '/tl/:id',
        component: () => import('../views/HistoryTLView.vue')
    },
    {
        path: `/access`,
        component: () => import('../views/AccessView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: '/verify/:token',
        component: () => import('../views/VerifyView.vue')
    },
    {
        path: '/reset/:token',
        component: () => import('../views/PasswordView.vue')
    },
    {
        path: '/create',
        component: () => import('../views/ArtistCreationView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: '/p-create',
        component: () => import('../views/PlaylistCreationView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: '/guess-create',
        component: () => import('../views/AdminGuessView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: '/collections',
        component: () => import('../views/ArtistsView.vue')
    },
    {
        path: '/c/:id',
        component: () => import('../views/ArtistView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../components/Artist/ArtistGames.vue')
            },
            {
                path: `edit`,
                component: () => import('../components/Artist/ArtistEditMenu.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
            {
                path: `mass`,
                component: () => import('../components/Artist/ArtistMass.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
            {
                path: `songs`,
                component: () => import('../components/Artist/ArtistEditSongs.vue')
            },
            {
                path: `access`,
                component: () => import('../components/Artist/ArtistEditAccess.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
            {
                path: `audits`,
                component: () => import('../components/Artist/ArtistEditAudits.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
            {
                path: `update`,
                component: () => import('../views/ArtistUpdateView.vue'),
                meta: {
                    requiresAccountData: true,
                },
            },
            {
                path: `worldcups`,
                component: () => import('../components/Artist/ArtistHistoryWC.vue')
            },
            {
                path: `songbattles`,
                component: () => import('../components/Artist/ArtistHistorySB.vue')
            },
            {
                path: `list`,
                component: () => import('../components/Artist/ArtistSongs.vue')
            },
            {
                path: `ranking`,
                component: () => import('../components/Artist/ArtistRankings.vue')
            },
            {
                path: `quizzes`,
                component: () => import('../components/Artist/ArtistQuizzes.vue')
            },
        ]
    },
    {
        path: `/song/:songid`,
        component: () => import('../components/Artist/ArtistEditSong.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: `/song/add/:artistid`,
        component: () => import('../components/Artist/ArtistAddSong.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: `/created`,
        component: () => import('../views/CreatedView.vue'),
        meta: {
            requiresAccountData: true,
        },
    },
    {
        path: `/invite/:invite`,
        component: () => import('../views/InviteView.vue')
    },
    {
        path: `/privacy`,
        component: () => import('../views/PrivacyView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// Global navigation guard
router.beforeEach(async (to, from, next) => {
    document.title = `Fandle - Online Music Games`
    window.scrollTo(0, 0)
    /*
    if (from && from.matched && from.matched[0] && from.matched[0].path == `/u/:username`) {
        store.commit(`CLEAR_PROFILE`)
    }*/
    if (to.meta.requiresAccountData) {
        if(await store.dispatch('GET_ACCOUNT')) {
            next()
        } else {
            const token = localStorage.getItem(`token`)
            if (token) {
                await axios.post(`https://guess-api-live-52f28b1eaf66.herokuapp.com/accounts/auth`, {token: token})
                .then((res) => {
                    store.commit(`SET_ACCOUNT`, res.data.account)
                    next()
                })
                .catch((err) => {
                    console.error(err)
                    next(`/login`)
                })
            } else {
                next(`/login`)
            }
        }
    } else {
        next()
    }
})

export default router