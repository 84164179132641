import { createStore } from 'vuex'

export default createStore({
    state: {
        apiUrl: `https://guess-api-live-52f28b1eaf66.herokuapp.com`, // https://guess-api-live-52f28b1eaf66.herokuapp.com
        Account: false,
        Profile: false,
        GuessTrack: {},
        Songs: null,
        ArtistData: false,
        Mobile: false,
    },
    actions: {
        GET_ACCOUNT(state) {
            return state.ArtistData
        }
    },
    mutations: {
        SET_ACCOUNT(state, account) {
            account.unreadNotifications = 0
            account.notifications.forEach(notificiation => {
                if (notificiation._read == 0) {
                    account.unreadNotifications++
                }
            })
            state.Account = account
        },
        CLEAR_ACCOUNT(state) {
            state.Account = null
        },
        SET_ARTISTDATA(state, data) {
            state.ArtistData = data
        },
        SET_SONGDATA(state, data) {
            state.Songs = data
        },
        SET_MOBILE(state) {
            state.Mobile = true
        },
    },
})
