<template>

    <AppNav :expanded="expanded" :expandedProfile="expandedProfile" @ToggleExpand="expanded = !expanded" @ToggleProfileExpand="expandedProfile = !expandedProfile" />
    <div id="pageContainer">
        <router-view v-if="!(isMobile && (expanded || expandedProfile))" />
    </div>
    <AppExpanded :expanded="expanded" @ToggleExpand="expanded = !expanded" />
    <AppProfileExpanded :expanded="expandedProfile" @ToggleExpand="expandedProfile = !expandedProfile" />

    <div id="consentContainer" v-if="false">
        <p>We use cookies to enhance your experience, serve personalized ads or content, and to analyze our traffic (See privacy terms <a href="/privacy">here</a>). By clicking "Accept All", you agree that you have read, and consent to our use of cookies and Google Adsense services. </p>
        <div id="editButton" class="consentButton" @click="Consent">
            <p>Accept All</p>
        </div>
    </div>

    <AppFooter />

</template>

<script>

    import axios from "axios"

    import AppNav from "./components/App/AppNav.vue"
    import AppFooter from "./components/App/AppFooter.vue"
    import AppExpanded from "./components/App/AppExpanded.vue"
    import AppProfileExpanded from "./components/App/AppProfileExpanded.vue"

    export default {
        components: {
            AppNav,
            AppFooter,
            AppExpanded,
            AppProfileExpanded
        },
        data() {
            return {
                expanded: false,
                expandedProfile: false,
                isMobile: false,
                cookies: true // localStorage.getItem(`CookieConsent`)
            }
        },
        methods: {
            Consent() {
                localStorage.setItem(`CookieConsent`, true)
                window.location.href = ""
            },
            CollapseExpandeds() {
                this.expanded = false
                this.expandedProfile = false
            },
        },
        mounted() {
            // Mobile?
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
                this.$store.commit(`SET_MOBILE`)
            }

            // Artist data
            let artist = localStorage.getItem(`lastArtist`)
                if (artist) {
                    axios.get(`${this.$store.state.apiUrl}/data/artist`, {
                        headers: {"artist": artist}
                    })
                    .then(res => {
                        // Set artist data
                        this.$store.commit(`SET_ARTISTDATA`, res.data)
                    })
                    .catch(err => {
                        console.log(`Failed to collect data from the API`)
                        console.error(err)
                    })
            }

            // Account data
            const token = localStorage.getItem(`token`)
            if (token) {
                axios.post(`${this.$store.state.apiUrl}/accounts/auth`, {token: token})
                .then((res) => {
                    this.$store.commit(`SET_ACCOUNT`, res.data.account)
                })
                .catch((err) => {
                    console.error(err)
                })
            }
        }
    }
  
</script>

<style>

    .adsbygoogle {
        margin-bottom: 20px;
    }

    #consentContainer {
        width: 100%;
        padding: 20px;
        position: fixed;
        display: flex;
        flex-direction: column;
        left: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.932);
        border-radius: 10px;
    }

    #consentContainer p {
        margin: auto;
    }

    .consentButton {
        margin: auto !important;
        margin-top: 20px !important;
        width: 200px !important;
    }

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

    body {
        background-color: #0F0F14;
        font-family: 'Montserrat', sans-serif;
    }

    #app {
        text-align: center;
        color: white;
    }

    .icon {
        margin-bottom: 5px;
    }

    #pageContainer {
        width: 100%;
        min-height: 100vh;
        margin: auto;
        margin-bottom: 200px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    #appId {
        width: 100%;
    }

    #br {
        width: 60%;
        height: 3px;
        margin: auto;
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: #8356f3;
    }

    .sectorTop {
        width: 100%;
        margin-bottom: 20px;
        padding: 20px 0px;
        background-color: #15151d;
    }

    #editBracket {
        background-color: rgba(39, 38, 38, 0.158);
        width: 70%;
        min-width: 350px;
        margin: auto;
        margin-top: 10px;
        padding: 15px;
        border: 2px solid #282B38;
        border-radius: 8px;
        text-align: left;
    }
    
    #editContainer {
        width: 60%;
        min-width: 320px;
        margin: auto;
        margin-left: 0px;
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    #editContainer input {
        width: 100%;
        padding: 5px;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
    }

    #editContainer textArea {
        width: 100%;
        padding: 5px;
        width: 80%;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
    }

    #editContainer select {
        width: 100%;
        padding: 5px;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
    }

    #editContainer h3 {
        margin-bottom: 10px;
    }

    #editContainer p {
        margin-bottom: 10px;
    }
    
    #editCheck {
        margin-left: 25px;
        -ms-transform: scale(2);
        -moz-transform: scale(2);
        -webkit-transform: scale(2);
        -o-transform: scale(2);
        transform: scale(2);
        padding: 10px;
    }

    #editButton {
        width: 100%;
        height: 5vh;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
        color: #733CFF;
        border: 1px solid #733CFF;
        border-radius: 5px;
    }

    #editButton:hover {
        cursor: pointer;
        background: #733cff15;
    }

    #editButton p {
        margin: auto;
        font-weight: bold;
    }

    #cancelButton {
        width: 100%;
        height: 5vh;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        color: #da3c3c;
        border: 1px solid #da3c3c;
        border-radius: 5px;
    }

    #cancelButton:hover {
        cursor: pointer;
        background: #da3c3c21;
    }

    #cancelButton p {
        margin: auto;
        font-weight: bold;
    }

    #error {
        padding: 5px;
        margin-top: 20px;
        border-radius: 5px;
        background-color: #743117;
    }

    a {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }

    a:hover {
        color: #8860ee;
    }

    .smallprint {
        color: gray;
    }

    #disclaimer {
        padding: 5px;
        margin-top: 40px;
        border-radius: 5px;
        background-color: #746a17;
    }

</style>
