<template>

    <div id="nav">

        <font-awesome-icon v-if="!expandedProfile" icon="fa-solid fa-list" id="expand" size="lg" @click="$emit(`ToggleExpand`)" />
        <a v-if="!this.$store.state.ArtistData" href="/" id="navTitle">Fandle</a>
        <a v-if="this.$store.state.ArtistData && this.$store.state.Mobile" :href="`/collections`" id="navTitle"><span id="artist">{{ this.$store.state.ArtistData.name }}</span></a>
        <a v-if="this.$store.state.ArtistData && !this.$store.state.Mobile" :href="`/`" id="navTitle">Fandle - <span id="artist">{{ this.$store.state.ArtistData.name }}</span></a>
        <font-awesome-icon  v-if="!expanded" icon="fa-solid fa-user" id="account" size="lg" @click="OpenProfile"
        :class="{notificationsPending: (this.$store.state.Account && this.$store.state.Account.unreadNotifications > 0)}" />

    </div>

</template>

<script>

    export default {
        props: [`expanded`, `expandedProfile`],
        methods: {
            OpenProfile() {
                if (this.$store.state.Account) {
                    this.$emit(`ToggleProfileExpand`)
                } else {
                    if (window.confirm(`You are not logged in, sign in now?`)) {
                        window.open(`/login`, `_blank`)
                    }
                }
            }
        }
    }
  
</script>

<style scoped>

    #navTitle {
        width: 55% !important;
        min-width: 270px !important;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        word-wrap: nowrap !important;
    }

    a {
        margin: auto;
        font-size: 24px;
        font-weight: bold;
    }
    
    #artist {
        color: #8356f3;
    }

    #nav {
        position: relative;
        width: 100%;
        height: 70px;
        margin-bottom: 30px;
        overflow: hidden;
        padding: 20px 0px;
        border-bottom: 1px solid #282B38;
    }

    #expand {
        position: absolute;
        top: 15px;
        left: 15px;
        background-color: #733CFF;
        border-radius: 50%;
        padding: 10px;
    }

    #expand:hover {
        cursor: pointer;
        user-select: none;
    }

    #account {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #733CFF;
        border-radius: 50%;
        padding: 10px;
    }

    #account:hover {
        cursor: pointer;
        user-select: none;
    }

    .notificationsPending {
        outline: 2px solid #da3c3c;
    }

</style>
